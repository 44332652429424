import React from 'react';
import {
    useParams
} from "react-router-dom";

function SamplePoemImage() {
    let { poem } = useParams();
    return (
        <div className="text-center mt-4">
            <img src={`/images/${poem}.jpg`} alt="Sample Poem" />
        </div>
    );
}

export default SamplePoemImage;