import React from 'react';

function SamplePoemsImage() {
    let images = [
        { image: "sample3.jpg", height: "600", width: "1000" },
        { image: "sample6.jpg", height: "600", width: "1000" },
        { image: "sample13.jpg", height: "600", width: "1000" },
        { image: "sample29.jpg", height: "600", width: "800" },
        { image: "sample48.jpg", height: "600", width: "1000" },
        { image: "sample59.jpg", height: "650", width: "1000" },
        { image: "sample70.jpg", height: "600", width: "925" },
        { image: "sample75.jpg", height: "600", width: "950" },
        { image: "sample82.jpg", height: "650", width: "925" },
        { image: "sample86.jpg", height: "650", width: "925" },
        { image: "sample90.jpg", height: "650", width: "925" }
    ]

    return (
        <div>
            <div className="jumbotron">
                <div className="container">
                    <h1 className="display-4">Sample Poems</h1>
                </div>
            </div>
            <div className="container">
                <div className="text-center pt-3">
                    {images.map((item, i) => {
                        // Return the element. Also pass key
                        return (<img alt="sample poem" height={item.height} width={item.width} src={`images/${item.image}`} />)
                    })}
                </div>
            </div>
        </div>
    );
}

export default SamplePoemsImage;