import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import {
    useParams
} from "react-router-dom";

import * as _ from 'lodash';

function SamplePoemText() {
    let poems = [
        {
            key: 'god-will',
            text: `
**GOD WILL RAISE ME SO,**

**I CAN CONTINUE TO DO HIS WORKS**
            
What a powerful thought,
            
to continue doing God's work after I die.
            
He is not finished with me.
            
There is this eternal-ongoing calling
            
of praising and worshiping Him
            
even after ***the appearance of my death*** sets in.
            
According to Him, there is more to do,
            
only there &#x2014;in His home&#x2014;
            
sin is not keeping me in chains.
            
And in my being with Him forever,
            
I am able to do more Divine work for Him.
            
I intend to serve Him with what He has given me
            
by showing my Love for His Father.
            
So, at my physical death, it is not over.
            
I **still** have this covenant with Him
            
and what He has given me,
            
what I accept and believe
            
makes it possible for me
            
to continue doing His eternal works.
            
I am here to do my part
            
in His world of saving souls
            
and bringing them into His kingdom.
            
**1 Corinthians 15:42-44**`
        }, {
            key: 'greater-is-he',
            text: `
**GREATER IS HE ... IS IN YOU**

1 John 4:4
            
In this life, though death makes its appearance,
            
there is Spiritual work to do in the next world.
            
Only there, my body and mind
            
will not be part of that journey,
            
what I have in my soul
            
will enable me
            
to do spiritual battle for God Almighty.
            
Having His guidance,
            
I will seek out His wants in this here-after place.
            
There is no Garden of Eden.
            
There is no such thing as Original sin.
            
There are no apostles, no books for guidance,
            
no biological parents to blame
            
not to be connected to You.
            
There is only a Divine connection
            
for those who want to be there.
            
You are in me,
            
guiding me once again in doing Your works.
            
There is a Spiritual glow
            
&#x2014;of having Oneness&#x2014;when connected to You.
            
This glow radiates I am part of You.
            
No evil can penetrate this glow.

Eternal Joy emanates Divine Love
            
which is pure, sacred and holy.
            
A beatitude of boundless energy
            
shows Your beauty and holiness.`
        }, {
            key: 'god-needs-to-be',
            text: `
**GOD NEEDS TO BE FIRST**

God needs to be first in all ways.
            
He is All, everything, and more.
            
He gave me life, breath,
            
and a body to explore this world.
            
Where do my thoughts and beliefs take me?
            
Am I focused on me in this physical world?
            
I must take my thoughts to a higher level,
            
one of Divine humanity and Spiritual rewards
            
that go beyond the here and now of all tomorrows.
            
Before God opens death's door to me,
            
will I make this connection with Him
            
knowing He is the reason for my existence?
            
If I put my attention on things rather than God,
            
my connection with Him will falter.
            
I must go through Christ for everything.
            
I can be rich and famous,
            
but this means nothing to God,
            
for He is all I see and have.
            
Unless I put Him first,
            
I have nothing of Divine meaning.
            
Fame, fortune, and family are Earthly goals.
            
But when I put God first,
            
I receive Divine rewards.
            
This direction, this connection, this relationship,
            
becomes a ***covenant*** with Jesus,
            
the Holy Spirit and His Father.`
        }, {
            key: 'god-planted-the-seed',
            text: `
**GOD PLANTED THE SEED &#x2014; JESUS**

A seed was placed here
            
for me to develop into Your Oneness,
            
to assist me in Your direction
            
and kingdom of everlasting life.
            
Without this seed—Jesus—
            
I am doomed to be in a place
            
where Satan's diabolical chains
            
hold my soul with him forever.
            
God placed Jesus onto this Earth
            
to save mankind from Satan's eternal home.
            
God's seed gives me a Divine life.
            
It runs abundant, never ending, never forsaken,
            
never mournful, always Joyful  
            
in Truth, Justice, and Divine Love.  
            
His seed shows me His way.
            
I must fertilize it with my actions
            
and believing His word—the Bible.
            
At the core of this tree
            
stands a Divine home of God's Glory
            
that radiates beyond my imagination.
            
It cannot be seen with human eyes,
            
touched with human hands,
            
or captured in place or time, for it is eternal.
            
It has no physical boundaries.
            
God planted this seed
            
so, I would be a connection to Him,
            
His way of life, His Holy house
            
where His Divine Love and Glory reside.`
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    let { poem } = useParams();
    return (
        <div className="text-center mt-4">
            <ReactMarkdown>{_.find(poems, {key: poem})?.text ?? ''}</ReactMarkdown>
        </div>
    );
}

export default SamplePoemText;