import React from 'react';

import ReactAudioPlayer from 'react-audio-player';

let audioFiles = [
    { file: "audio/It-Is-No-Longer-I.mp3", name: "It Is No Longer I" },
    { file: "audio/My-Spiritual-Progress.mp3", name: "My Spiritual Progress" },
    { file: "audio/Testing-My-Faith.mp3", name: "Testing My Faith" },
    { file: "audio/What-Is-Joy.mp3", name: "What Is Joy" },
    { file: "audio/Your-Fullness-of-the-Holy-Spirit.mp3", name: "Your Fullness of the Holy Spirit" }
]

const credits = [
    "\"Elf Meditation\" Kevin MacLeod (incompetech.com)\nLicensed under Creative Commons: By Attribution 4.0 License\nhttp://creativecommons.org/licenses/by/4.0/",
    "\"Organic Meditations Three\" Kevin MacLeod (incompetech.com)\nLicensed under Creative Commons: By Attribution 4.0 License\nhttp://creativecommons.org/licenses/by/4.0/",
    "\"Meditation Impromptu 01\" Kevin MacLeod (incompetech.com)\nLicensed under Creative Commons: By Attribution 4.0 License\nhttp://creativecommons.org/licenses/by/4.0/",
    "\"Ethereal Relaxation\" Kevin MacLeod (incompetech.com)\nLicensed under Creative Commons: By Attribution 4.0 License\nhttp://creativecommons.org/licenses/by/4.0/",
    "\"Ebbs and Flows\" Kevin MacLeod (incompetech.com)\nLicensed under Creative Commons: By Attribution 4.0 License\nhttp://creativecommons.org/licenses/by/4.0/",
    "\"Wind of the Rainforest\" Kevin MacLeod (incompetech.com)\nLicensed under Creative Commons: By Attribution 4.0 License\nhttp://creativecommons.org/licenses/by/4.0/",
    "\"Kalimba Relaxation Music\" Kevin MacLeod (incompetech.com)\nLicensed under Creative Commons: By Attribution 4.0 License\nhttp://creativecommons.org/licenses/by/4.0/"
]

function AudioScreen() {
    return (
        <div>
            <div className="jumbotron">
                <div className="container">
                    <h1 className="display-4">Audio Files</h1>
                </div>
            </div>
            <div className="container">
                <div className="row pt-3">
                    {audioFiles.map((item, i) => {
                        return (
                            <div key={item.file} className="col mt-3 text-center">
                                <div>{item.name}</div>
                                <ReactAudioPlayer
                                    src={item.file}
                                    controls
                                />
                                <div>
                                <a href={`/${item.file}`} download>
                                    Download {item.name}
                                </a>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="container mt-4 text-center">
                <h4>Feel free to download and share any of these audio recordings!</h4>
                <h5>If you would like to reach out to the voice on these recordings, please send an email to <a href="mailto:albeedarnt@gmail.com">albeedarnt@gmail.com</a></h5>
            </div>
            <div className="container mt-4">
                <div>Credits for Music</div>
                <div className="row">
                    {credits.map((item, i) => {
                        return (
                            <small className="col" key={`credits ${i}`}><p>{item.split('\n').map((smallItem, j) => <div key={`${i}${j}`}>{smallItem}</div>)}</p></small>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default AudioScreen;