import React from 'react';

function Home() {
    return (
        <div>
            <div className="jumbotron">
                <div className="container">
                    <h1 className="display-4">3-in-1 Book</h1>
                </div>
            </div>
            <div className="container">
                <div className="text-center">
                    <div className="row">
                    <div className="col-md d-flex">
                            <div className="mb-4 flex-fill">
                            <img width="400" height="500" src="/images/EnterTheHolySpiritBookCover.jpg" className="card-img-top" alt="Book Cover" />
                                <div className="card-body">
                                    <p className="card-text">Purchase the Enter the Holy Spirit 3-in-1 Book</p>
                                    <div>
                                        <a href="http://www.amazon.com/Holy-Spirit-3-1/dp/1519135300/" className="btn btn-primary mr-1" target="_blank" rel="noopener noreferrer">Amazon</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;