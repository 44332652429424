import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import './App.css';

import Home from './Home';
import SamplePoemsImage from './sample-poems/SamplePoemsImage';
import SamplePoemImage from './sample-poems/SamplePoemImage';
import Books from './Books';
import AudioScreen from './Audio';
import SamplePoemText from './sample-poems/SamplePoem';

function App() {
  return (
    <Router>
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <Link className="navbar-brand" to="/">Enter The Holy Spirit</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <NavLink activeClassName="active" className="nav-link" to="/" exact={true}>Home <span className="sr-only">(current)</span></NavLink>
              </li>
              <li className="nav-item">
                <NavLink activeClassName="active" className="nav-link" to="/combo">3-in-1 <span className="sr-only">(current)</span></NavLink>
              </li>
              <li className="nav-item">
                <NavLink activeClassName="active" className="nav-link" to="/sample-poems">Sample Poems <span className="sr-only">(current)</span></NavLink>
              </li>
              <li className="nav-item">
                <NavLink activeClassName="active" className="nav-link" to="/audio">Audio<span className="sr-only">(current)</span></NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <main>
          <Switch>
            <Route path="/sample-poems">
              <SamplePoemsImage />
            </Route>
            <Route path="/audio">
              <AudioScreen />
            </Route>
            <Route path="/books">
              <Books />
            </Route>
            <Route path="/combo">
              <Home />
            </Route>
            <Route path="/sample/:poem">
              <SamplePoemImage />
            </Route>
            <Route path="/sample-text/:poem">
              <SamplePoemText />
            </Route>
            <Route path="/">
              <Books />
            </Route>
          </Switch>
          <div className="container">
            <hr />
          </div>
        </main>
        <footer className="container">
        <p>Copyright {new Date().getFullYear()} - Enter The Holy Spirit - Joe Rotonda - All Rights Reserved</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
